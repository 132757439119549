import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@app/auth/auth.service";

@Injectable()
export class AuthGuardTeacherService {
  constructor(
    public router: Router,
    public authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(["auth/"], {
        queryParams: { next: state.url },
      });
      return false;
    }
    return true;
  }
}
